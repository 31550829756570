<template>
  <Content
    data-tour-step="headline"
    :item="item"
    :parent="parent"
    :class="{ 'headline-gradient': hasGradient }"
  />
</template>

<script>
export default {
  name: 'Headline',

  components: {
    Content: () => import('@/components/Items/Content'),
  },

  props: {
    item: {
      type: Object,
      default: () => ({}),
    },

    parent: {
      type: Object,
      default: () => ({}),
    },
  },

  computed: {
    hasGradient() {
      return this.item.props.gradient
    },
  },
}
</script>

<style lang="postcss">
.headline-gradient h2 {
  display: inline-block;
  background: var(--textGradient);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
</style>
